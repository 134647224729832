import { Paper } from "@mui/material";

import { HeadCell } from "~/components/type/headCell.type";
import { GenericObjectWithFields } from "~/types/common/generic.types";

import { CustomizableTableBody } from "./CustomizableTableBody";
import { CustomizableTableHead } from "./CustomizableTableHead";
import styles from "./index.module.scss";

interface Props<T extends GenericObjectWithFields> {
  headCells: HeadCell<T>[];
  dataRows: T[];
  stickyHead?: boolean;
  stickyColumn: boolean;
  onRowClick?: (id: number) => void;
  hasHeader?: boolean;
  hasFoot?: boolean;
}

export const CustomizableTable = <T extends GenericObjectWithFields>({
  headCells,
  dataRows,
  stickyHead,
  stickyColumn,
  children,
  hasHeader = true,
  hasFoot = true,
  onRowClick = () => {},
}: React.PropsWithChildren<Props<T>>) => {
  return (
    <Paper elevation={1} sx={{ height: "100%", padding: 0, width: "100%" }}>
      <div
        className={`${styles.tableContainer} ${!hasFoot && styles.fullHeight}`}
      >
        <table
          className={`${styles.table} ${
            stickyColumn ? styles.stickyColumn : ""
          }`}
        >
          {hasHeader && (
            <CustomizableTableHead
              headCells={headCells}
              sticky={stickyHead}
              noUpperBorder={true}
            />
          )}
          <CustomizableTableBody
            headCells={headCells}
            dataRows={dataRows}
            onRowClick={onRowClick}
          />
        </table>
      </div>
      {hasFoot && <div className={`${styles.footerContainer}`}>{children}</div>}
    </Paper>
  );
};
