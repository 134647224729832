import SimpleDatePicker from "~/components/simpleDatePicker/SimpleDatePicker";
import { CustomizableTable } from "~/components/table/CustomizableTable";
import { HeadCell } from "~/components/type/headCell.type";
import { GenericObject } from "~/types/common/generic.types";
import CustomFooter from "~/components/customFooter/CustomFooter";
import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { User } from "~/types/data/User.types";
import getUsers from "~/api/user/getUsers";

const BillabilitySection = () => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await getUsers();
      if (res.success && res.data) {
        setUsers(res.data);
      }
    };
    fetchUsers();
  }, []);

  const headCells: HeadCell<User>[] = [
    { id: "imageUrl", type: "image", label: "", secondField: "firstName" },
    { id: "salary", type: "number", label: "" },
  ];

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.titleContainer}`}>
        <h2 className={`${styles.title}`}>Billability</h2>
      </div>
      <div className={`${styles.datePickersContainer}`}>
        <SimpleDatePicker labelFor={"startDate"} target={`Start date`} />
        <SimpleDatePicker labelFor={"endDate"} target={`End date`} />
      </div>
      <div className={`${styles.tableContainer}`}>
        <CustomizableTable
          headCells={headCells}
          dataRows={users}
          stickyColumn={false}
          hasHeader={false}
          hasFoot={true}
        >
          <CustomFooter flag="users" name="Overall" average={20} />
        </CustomizableTable>
      </div>
    </div>
  );
};

export default BillabilitySection;
