export default function validatePassword(password: string) {
  if (password.length < 8) {
    return false;
  }
  const hasUpperCase = /[A-Z]/.test(password);
  if (!hasUpperCase) {
    return false;
  }
  const hasLowerCase = /[a-z]/.test(password);
  if (!hasLowerCase) {
    return false;
  }
  const hasNumber = /[0-9]/.test(password);
  if (!hasNumber) {
    return false;
  }
  const hasSymbol = /[^\w\s]|_/.test(password);
  if (!hasSymbol) {
    return false;
  }
  return true;
}
