import { ApiGetResponse } from "~/types/common/auth.types";
import { Project } from "~/types/data/Project.types";

import { sendRequest } from "../clients/axios";

export default async function getProjects(): Promise<
  ApiGetResponse<Project[]>
> {
  try {
    const res = await sendRequest<never, Project[]>("get", "/project/list");
    return { success: true, data: res.data };
  } catch (error: unknown) {
    return { success: false };
  }
}
