import { HeadCell } from "~/components/type/headCell.type";
import { GenericObjectWithFields } from "~/types/common/generic.types";

import styles from "./index.module.scss";

interface Props<T> {
  headCells: HeadCell<T>[];
  sticky?: boolean;
  noUpperBorder?: boolean;
}

export const CustomizableTableHead = <T extends GenericObjectWithFields>({
  headCells,
  sticky,
  noUpperBorder,
}: Props<T>) => {
  return (
    <thead
      className={` ${sticky ? styles.sticky : ""} ${
        noUpperBorder ? styles.noUpperBorder : ""
      }`}
    >
      <tr>
        {headCells.map((headCell) => {
          const key = headCell.id.toString();
          const label = headCell.label;
          return (
            <th
              key={key}
              className={headCell.isEmpty ? styles.noLeftBorder : ""}
            >
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
