import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

const Display = () => {
  return (
    <div
      className={`h-100 bgColor-blue flex flex-col justify-center .align-center ${styles.display}`}
    >
      <SVGContainer width="80%" height="86" imagePath="/assets/logo.svg">
        logo
      </SVGContainer>
    </div>
  );
};

export default Display;
