function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date: string) {
  const dateObject = new Date(date);
  return [
    padTo2Digits(dateObject.getDate()),
    padTo2Digits(dateObject.getMonth() + 1),
    dateObject.getFullYear(),
  ].join("/");
}
