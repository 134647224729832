import { sendRawRequest } from "~/api/clients/axios";
import { AxiosHttpError } from "~/types/common/error.types";

interface SetNewPasswordInput {
  email: string;
}
interface SendForgotPasswordCodeResponse {
  CodeDeliveryDetails: CodeDeliveryDetails;
}

interface CodeDeliveryDetails {
  Destination: string;
  DeliveryMedium: string;
  AttributeName: string;
}

export default async function sendForgotPasswordCode(
  email: SetNewPasswordInput
) {
  try {
    const res = await sendRawRequest<
      SetNewPasswordInput,
      SendForgotPasswordCodeResponse
    >("post", "/auth/forgot-password", email);
    const {
      data: { CodeDeliveryDetails },
    } = res;

    if (!!CodeDeliveryDetails) return { success: true, data: res.data };
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        success: false,
        action: typeof message === "string" ? message : message[0],
      };
    }
  }
  return {
    success: false,
    action: "Unkown Error",
  };
}
