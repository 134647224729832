import React, { useState } from "react";

import AddOrUpdateTeamMemberForm from "~/components/form/AddTeamMemberForm";
import { CustomizableTable } from "~/components/table/CustomizableTable";
import { HeadCell } from "~/components/type/headCell.type";
import AddButton from "~/components/UI/AddButton";
import CustomModal from "~/components/UI/CustomModal";
import { useAdminContext } from "~/context/adminContext";
import { User } from "~/types/data/User.types";

const Team = () => {
  const { adminDataState, setAdminState } = useAdminContext();

  const [showAddTeamMember, setShowAddTeamMember] = useState(false);

  const [selectedTeamMember, setSelectedTeamMember] = useState<User | null>(
    null
  );

  const showTeamMemberFormHandler = () => {
    setShowAddTeamMember(true);
  };

  const hideTeamMemberFormHandler = () => {
    setSelectedTeamMember(null);
    setShowAddTeamMember(false);
  };

  const updateSelectedTeamMember = (user: User) => {
    setAdminState((previousAdminState) => {
      return {
        ...previousAdminState,
        users: previousAdminState.users.map((previousUser) => {
          return previousUser.id === user.id ? user : previousUser;
        }),
      };
    });
    hideTeamMemberFormHandler();
  };

  const createTeamMember = (user: User) => {
    setAdminState((previousAdminState) => {
      return {
        ...previousAdminState,
        users: [...previousAdminState.users, user],
      };
    });
    hideTeamMemberFormHandler();
  };

  const memberClickedHandler = (id: number) => {
    const selectedTeamMember = adminDataState.users.find((member) => {
      return member.id === id;
    });
    if (selectedTeamMember) {
      setSelectedTeamMember(selectedTeamMember);
      setShowAddTeamMember(true);
    }
  };

  const headCells: HeadCell<User>[] = [
    {
      id: "imageUrl",
      type: "image",
      label: "",
      isEmpty: true,
      secondField: "firstName",
    },
    { id: "firstName", type: "string", label: "First Name" },
    { id: "lastName", type: "string", label: "Last Name" },
    { id: "entryDate", type: "date", label: "Entry Date" },
    { id: "salary", type: "number", label: "Salary (€/month)" },
    { id: "position", type: "string", label: "Position" },
    {
      id: "vacationDays",
      type: "number",
      label: "Days of vacations remaining",
    },
  ];

  return (
    <div className="hp-100">
      <CustomModal
        onCancel={hideTeamMemberFormHandler}
        show={showAddTeamMember}
      >
        <AddOrUpdateTeamMemberForm
          teamMember={selectedTeamMember}
          onUpdate={updateSelectedTeamMember}
          onCreate={createTeamMember}
        />
      </CustomModal>
      <CustomizableTable
        dataRows={adminDataState.users}
        headCells={headCells}
        stickyHead={true}
        stickyColumn={true}
        onRowClick={memberClickedHandler}
      >
        <React.Fragment>
          <div style={{ padding: "10px 20px" }}>
            <AddButton
              text="Add a member"
              onClick={showTeamMemberFormHandler}
            />
          </div>
        </React.Fragment>
      </CustomizableTable>
    </div>
  );
};

export default Team;
