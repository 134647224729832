import axios from "axios";

import { ApiLoginResponse } from "~/types/common/auth.types";

export function setToken() {
  const accessToken: string | null =
    typeof window !== "undefined" ? localStorage.getItem("AccessToken") : null;
  if (accessToken) axios.defaults.headers.common.Authorization = accessToken;
}

export function storeAuthResult(response: ApiLoginResponse) {
  localStorage.setItem(
    "AccessToken",
    response.AuthenticationResult.AccessToken
  );
  if (!!response.AuthenticationResult.RefreshToken)
    localStorage.setItem(
      "RefreshToken",
      response.AuthenticationResult.RefreshToken
    );
  setToken();
}

export function removeAuth() {
  localStorage.removeItem("RefreshToken");
  localStorage.removeItem("AccessToken");
  setToken();
}
