import React from "react";

import AuthenticationForm from "~/components/auth/AuthenticationForm";
import Display from "~/components/UI/Display";

const Authentication: React.FC = () => {
  return (
    <div className="flex h-100">
      <div className={"w-40"}>
        <Display />
      </div>
      <div className={"w-60"}>
        <AuthenticationForm />
      </div>
    </div>
  );
};

export default Authentication;
