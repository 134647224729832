import { areDayPropsEqual } from "@mui/lab/PickersDay/PickersDay";
import React from "react";
import styles from "./CustomFooter.module.scss";
import plus from "./plus.png";

interface Props {
  flag: "projects" | "users" | "planning";
  name: string;
  total?: number;
  value?: number;
  average?: number;
  onClick?: ()=>void;
}

const CustomFooter: React.FC<Props> = ({
  flag,
  name,
  total,
  value,
  average,
  onClick,
}: Props) => {
  return (
    <React.Fragment>
      {flag === "projects" && (
        <div className={styles.container} style={{ paddingLeft: "0px" }}>
          <div className={styles.item}>{name}</div>
          <div className={styles.item}>{total}</div>
          <div className={styles.item}>{value}</div>
          <div className={styles.item}>{average}</div>
        </div>
      )}
      {flag === "users" && (
        <div className={styles.container}>
          <div className={styles.item}>{name}</div>
          <div className={styles.item}>{average}</div>
        </div>
      )}
      {flag === "planning" && (
        <div className={styles.verticalContainer}>
          <div className={styles.item}>{name}</div>
          <img src={plus} className={styles.pluss} onClick={onClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomFooter;
