import React from "react";

import { Project } from "~/types/data/Project.types";
import { User } from "~/types/data/User.types";

export interface AdminContextData {
  users: User[];
  projects: Project[];
  mode: string;
}

export type AdminContextContent = {
  adminDataState: AdminContextData;
  setAdminState: React.Dispatch<React.SetStateAction<AdminContextData>>;
};

export const AdminContext = React.createContext<AdminContextContent>({
  adminDataState: { users: [], projects: [], mode: "" },
  setAdminState: () => {},
});

export const useAdminContext = () => React.useContext(AdminContext);
