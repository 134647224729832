import { Avatar } from "@mui/material";
import { FunctionComponent } from "react";

import styles from "./index.module.scss";

interface Props {
  imageLink: string;
  name?: string | number;
}

export const NameCard: FunctionComponent<Props> = ({ imageLink, name }) => {
  const displayName = typeof name === "number" ? name.toString() : name;
  return (
    <div className={`${styles.card}`}>
      {displayName && (
        <div className="w-60 text-center">
          <p>{name}</p>
        </div>
      )}
      <div className="w-40 flex justify-center flex-col">
        <Avatar src={imageLink} alt={displayName ?? ""} />
      </div>
    </div>
  );
};
