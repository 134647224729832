import { sendRawRequest } from "~/api/clients/axios";
import { AxiosHttpError } from "~/types/common/error.types";

export type ChangePasswordInput = {
  email: string;
  code: string;
  password: string;
};

export default async function changePassword(input: ChangePasswordInput) {
  try {
    const res = await sendRawRequest<
      ChangePasswordInput,
      Record<string, never>
    >("post", "/auth/confirm-forgot-password", input);
    return { success: true, data: res.data };
  } catch (err) {
    const axiosError = err as AxiosHttpError;
    if (axiosError.response?.data) {
      const { message } = axiosError.response.data;
      return {
        success: false,
        action: typeof message === "string" ? message : message[0],
      };
    }
    return {
      success: false,
      action: "Unkown Error",
    };
  }
}
