import React, { useState } from "react";
import styles from "./MonthTimeline.module.scss";
import leftArrow from "./left-arrow.png";
import rightArrow from "./right-arrow.png";
import { getMonthsArray } from "~/utils/functions/getMonthsArray";
import moment from "moment";
import { areDayPropsEqual } from "@mui/lab/PickersDay/PickersDay";

interface Props {
  months: string[];
  setCurrentMonth: (month:string) => void
  
}

const MonthTimeline = ({ months, setCurrentMonth}: Props) => {
  const [indexes, setIndexes] = useState<[number, number]>([0, 11]);

  const leftArrowClickHandler = () => {
    if (indexes[0] > 0) {
      setIndexes((prev) => [prev[0] - 1, prev[1] - 1]);
    }
  };

  const rightArrowClickHandler = () => {
    if (indexes[1] <= months.length) {
      setIndexes((prev) => [prev[0] + 1, prev[1] + 1]);
    }
  };

  //should be array of Date
  const displayedMonths: string[] = months.slice(indexes[0], indexes[1] + 1);

  return (
    <React.Fragment>
      <div className={styles.calendarContainer}>
        <img
          className={`${styles.arrowImage} ${styles.leftArrow}`}
          src={leftArrow}
          onClick={leftArrowClickHandler}    
        />

        {displayedMonths.map((date, index) => (
          <div
            key={`main-months-${index}`}
            onClick={()=>{setCurrentMonth(date)}}
            className={styles.month}
          >
            {date}
          </div>
        ))}

        <img
          className={styles.arrowImage}
          src={rightArrow}
          onClick={rightArrowClickHandler}
        />
      </div>
    </React.Fragment>
  );
};

export default MonthTimeline;
