import { LocalizationProvider } from "@mui/lab";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import MUIDatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import { DateTime } from "luxon";

import styles from "./index.module.scss";

interface Props {
  value: DateTime | null;
  label: string | JSX.Element;
  onChange: (date: DateTime | null) => void;
}
export default function DatePicker({ value, label, onChange }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale={"fr"}>
      <div
        className={"flex flex-col"}
        style={{ width: "100%", justifyContent: "center", margin: "0.4em 0" }}
      >
        <label style={{ marginBottom: "2vh" }}>{label}</label>
        <MUIDatePicker
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              size="small"
              className={`${styles.datePicker}`}
              {...params}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
