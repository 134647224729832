import React from "react";
import { useNavigate } from "react-router-dom";

import { logout } from "~/api/auth/login";
import SVGContainer from "~/components/UI/SVGContainer";
import { useUserContext } from "~/context/userContext";

import Navigation from "../Navigation";
import styles from "./index.module.scss";

const NavigationBar = () => {
  const navigate = useNavigate();
  const { setUserState } = useUserContext();
  return (
    <React.Fragment>
      <div
        className={`${styles.logo}`}
        onClick={() => {
          logout();
          setUserState({ connected: false });
          navigate("/auth", { replace: true });
        }}
        style={{ height: "100%", cursor: "pointer" }}
      >
        <SVGContainer
          width="200px"
          height="100%"
          imagePath="/assets/logo.svg"
        />
      </div>

      <div className={`${styles.navigation}`}>
        <Navigation />
      </div>
    </React.Fragment>
  );
};

export default NavigationBar;
