import { Button } from "@mui/material";
import React from "react";

interface Props {
  backgroundColor?: string;
  color?: string;
  borderRadius?: string | number;
  type?: "button" | "submit";
  form?: string;
  variant?: "text" | "contained" | "outlined";
  borderColor?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const FlatButton = (props: React.PropsWithChildren<Props>) => {
  return (
    <Button
      type={props.type || "button"}
      form={props.form || ""}
      variant={props.variant || "text"}
      style={{
        backgroundColor: props.disabled
          ? `${props.backgroundColor}88`
          : props.backgroundColor,
        color: props.color,
        borderRadius: props.borderRadius,
        border: props.borderColor ? "2px solid " + props.borderColor : "",
        boxShadow: "none",
        textTransform: "none",
        letterSpacing: "normal",
        fontSize: "large",
        padding: "15px 26px",
        height: "40px",
        width: "100%",
      }}
      disabled={props.disabled}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.children}
    </Button>
  );
};
