import { HeadCell } from "~/components/type/headCell.type";
import { NameCard } from "~/components/UI/NameCard";
import { GenericObjectWithFields } from "~/types/common/generic.types";
import { User } from "~/types/data/User.types";
import { formatDate } from "~/utils/functions/formatDate";

import styles from "./index.module.scss";

interface Props<T extends GenericObjectWithFields> {
  headCells: HeadCell<T>[];
  dataRow: T;
  onClick: (id: number) => void;
}

export const CustomizableTableRow = <T extends GenericObjectWithFields>({
  headCells,
  dataRow,
  onClick,
}: Props<T>) => {
  {
    const clickHandler = (id: number) => {
      onClick(id);
    };
    const row: JSX.Element[] = [];
    const pushToRow = (key: string, data: JSX.Element | string | number) => {
      row.push(<td key={key}>{data}</td>);
    };

    for (const headCell of headCells) {
      const key = headCell.id.toString();
      switch (headCell.type) {
        case "image":
          const image = dataRow[headCell.id] as string;
          const secondField = headCell.secondField
            ? (dataRow[headCell.secondField] as string)
            : undefined;
          pushToRow(key, <NameCard imageLink={image} name={secondField} />);
          break;
        case "date":
          const date = dataRow[headCell.id] as string;
          pushToRow(key, formatDate(date));
          break;
        case "boolean":
          pushToRow(key, dataRow[headCell.id] ? "yes" : "no");
          break;
        case "numberArray":
          const numberArray = dataRow[headCell.id] as number[];
          pushToRow(key, numberArray.join(", "));
          break;
        case "stringArray":
          const stringArray = dataRow[headCell.id] as string[];
          pushToRow(key, stringArray.join(", "));
          break;
        case "userArray":
          const object = dataRow[headCell.id] as User[];
          pushToRow(
            key,
            object
              .map((user) => {
                return `${user.firstName} ${user.lastName.charAt(0)}.`;
              })
              .join(", ")
          );
          break;
        default:
          const data = dataRow[headCell.id] as string | number;
          pushToRow(key, data);
      }
    }
    const key = dataRow.id;
    return (
      <tr
        key={key}
        className={`${styles.row}`}
        onClick={clickHandler.bind(this, key)}
      >
        {row}
      </tr>
    );
  }
};
