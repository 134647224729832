import moment from 'moment'

export const getMonthsArray = () =>{
    const startDate = moment('2021-09-21');
    const endDate = moment().add(6,'month');
    const result = [];

    if (endDate.isBefore(startDate)) {
        throw "End date must be greater than start date."
    }      

    while (startDate.isBefore(endDate)) {
        result.push(startDate.format("YYYY-MM"));
        startDate.add(1, 'month');
    }

    return result;
}