import { useEffect, useState } from "react";

import SVGContainer from "~/components/UI/SVGContainer";
import { useAdminContext } from "~/context/adminContext";

import styles from "./index.module.scss";

const NavItem: React.FC<{ title: string; mode: string; imagePath: string }> = ({
  title,
  mode,
  imagePath,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { adminDataState, setAdminState } = useAdminContext();

  useEffect(() => {
    if (adminDataState.mode === mode) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [adminDataState.mode, setIsActive]);

  const clickHandler = (event: React.FormEvent) => {
    if (isActive) {
      event.preventDefault();
    }
    if (mode !== adminDataState.mode) {
      setAdminState((previousAdminState) => {
        return {
          ...previousAdminState,
          mode: mode,
        };
      });
    }
  };

  return (
    <div
      onClick={clickHandler}
      className={`${styles["nav-link"]} ${isActive ? styles.active : ""}`}
    >
      <span>
        <SVGContainer width="100%" height="100%" imagePath={imagePath} />
        {title}
      </span>
    </div>
  );
};

export default NavItem;
