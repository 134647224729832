import { HeadCell } from "~/components/type/headCell.type";
import { GenericObjectWithFields } from "~/types/common/generic.types";

import { CustomizableTableRow } from "../CustomizableTableRow";
import styles from "./index.module.scss";

interface Props<T extends GenericObjectWithFields> {
  headCells: HeadCell<T>[];
  dataRows: T[];
  onRowClick: (id: number) => void;
}

export const CustomizableTableBody = <T extends GenericObjectWithFields>({
  headCells,
  dataRows,
  onRowClick,
}: Props<T>) => {
  return (
    <tbody className={`${styles.tbody}`}>
      {dataRows.map((dataRow, index) => {
        return (
          <CustomizableTableRow<T>
            key={index.toString()}
            headCells={headCells}
            dataRow={dataRow}
            onClick={onRowClick}
          />
        );
      })}
    </tbody>
  );
};
