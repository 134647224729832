import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Timeline, {
  ReactCalendarGroupRendererProps,
  TimelineGroupBase,
} from "react-calendar-timeline";
import styles from "./Planning.module.scss";
import { User } from "~/types/data/User.types";
import getUsers from "~/api/user/getUsers";
import addIcon from "./plus.png";
import { getMonthsArray } from "~/utils/functions/getMonthsArray";
import MonthTimeline from "~/components/monthTimeline/MonthTimeline";
import CustomFooter from "~/components/customFooter/CustomFooter";
import CustomModal from "~/components/UI/CustomModal";

const Planning = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string>();
  const [showAddMemberForm, setShowAddMemberForm] = useState(false);
  const [showAddStatusForm, setShowAddStatusForm] = useState(false);


  const months = getMonthsArray();
 
  useEffect(() => {
    const fetchUsers = async () => {
      const res = await getUsers();
      if (res.success && res.data) {
        setUsers(res.data);
      }
    };
    fetchUsers();
  }, []);

 
  type UsersRendered = Partial<User> & TimelineGroupBase;

  const usersGroup: UsersRendered[] = users.map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    imageUrl: user.imageUrl,
    group: user.id,
    image: addIcon,
    title: user.firstName,
  }));

  
  const items = [
    {
      id: 1,
      group: 41,
      title: "heyy",
      start_time: moment("2022-04-12", "YYYY-MM-DD"),
      end_time: moment("2022-04-17", "YYYY-MM-DD"),
    },
    {
      id: 2,
      group: 41,
      title: "heyy",
      start_time: moment("2022-05-12", "YYYY-MM-DD"),
      end_time: moment("2022-05-17", "YYYY-MM-DD"),
    },
  ];

  const addStatusHandler = () => {
    setShowAddStatusForm(true)
  };
  const addMemberHandler= ()=>{
    setShowAddMemberForm(true)
  }
  const hideFormHandler = () => {
    setShowAddStatusForm(false)
    setShowAddMemberForm(false);
  };

  // console.log("*********MOMENT**********");
  // console.log(moment());
  // console.log("*********START**********");
  // console.log(start);
  // console.log(moment("2021-12-12", "YYYY-MM-DD"));
  // console.log("*********END**********");
  // console.log(end);
  // console.log(moment("2021-12-17", "YYYY-MM-DD"));
  // console.log("*******************");

  const start = moment(currentMonth, "YYYY-MM-DD");
  const end = moment(start).add(1, "month");
  
 
  const usersRenderer = ({
    group,
  }: ReactCalendarGroupRendererProps<UsersRendered>) => {
    return (
      <div className={styles.planRow}>
        <p className={styles.employeeName}>{group.firstName}</p>
        <img src={group.imageUrl} className={styles.employeeImage} />
        <img
          src={addIcon}
          className={styles.addButton}
          onClick={addStatusHandler}
        />
      </div>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <CustomModal
        onCancel={hideFormHandler}
        show={showAddStatusForm}
      >
        Add status form is coming soon
      </CustomModal>
      
      <CustomModal
        onCancel={hideFormHandler}
        show={showAddMemberForm}
      >
        Add member form is coming soon
      </CustomModal>

      <div className={styles.monthsContainer}>
        <MonthTimeline months={months} setCurrentMonth={setCurrentMonth} />
      </div>

      <div className={styles.timelineContainer}>
        <Timeline
          defaultTimeStart={start}
          defaultTimeEnd={end}
          visibleTimeStart={start}
          visibleTimeEnd={end}
          groups={usersGroup}
          items={items}
          groupRenderer={usersRenderer}
          lineHeight={50}
          sidebarWidth={220}
        />
      </div>
      <div className={styles.footer}>
        <CustomFooter flag="planning" name="Add a member" onClick={addMemberHandler}/>
      </div>
    </div>
  );
};

export default Planning;
