import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Authentication from "~/pages/Authentication";
import Dashboard from "~/pages/Dashboard";

import { AuthenticatedRoute } from "./AuthenticatedRoute/";
import { NotAuthenticatedRoute } from "./NotAuthenticatedRoute";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthenticatedRoute />}>
          <Route element={<Navigate to="/dashboard" />} path="/" />
        </Route>
        <Route path="/auth" element={<NotAuthenticatedRoute />}>
          <Route element={<Authentication />} path="/auth" />
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<Dashboard />} path="/dashboard" />
        </Route>
        <Route
          path="/*"
          element={
            <div>
              <h1>404 page</h1>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
