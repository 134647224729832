import Joi from "joi";

import { CreateUserInput } from "~/types/data/User.types";

export const teamMemberFormValidationSchema = Joi.object<CreateUserInput>({
  firstName: Joi.string()
    .required()
    .error(new Error("First name can't be empty.")),
  lastName: Joi.string()
    .required()
    .error(new Error("Last name can't be empty.")),
  email: Joi.string()
    .email({ tlds: false })
    .required()
    .error(new Error("Invalid email.")),
  entryDate: Joi.string()
    .isoDate()
    .required()
    .error(new Error("Invalid date.")),
  position: Joi.string()
    .required()
    .error(new Error("Position can't be empty.")),
  salary: Joi.number()
    .required()
    .not(0)
    .error(new Error("Salary can't be empty.")),
  status: Joi.string().required().error(new Error("Status must be selected.")),
  image: Joi.string().required().error(new Error("Image can't be empty.")),
});
