import "./styles/globals.scss";

import { useEffect, useState } from "react";

import { getUserInfo } from "./api/auth/getUserInfo";
import { UserContext, UserContextData } from "./context/userContext";
import AppRoutes from "./navigation/AppRoutes";

const App = () => {
  const [user, setUser] = useState<UserContextData>();

  const initUserInfo = async () => {
    const response = await getUserInfo();
    if (response.success) {
      setUser({ ...response.data, connected: true });
    } else {
      setUser({ connected: false });
    }
  };

  useEffect(() => {
    initUserInfo();
  }, []);

 

  return (
    <UserContext.Provider value={{ userState: user, setUserState: setUser }}>
      <AppRoutes />
    </UserContext.Provider>
  );
};

export default App;
