import { FormHelperText } from "@material-ui/core";
import { OutlinedInput } from "@mui/material";
import React, { FunctionComponent } from "react";

import styles from "./index.module.scss";

interface Props {
  placeholder?: string;
  label?: string;
  identifier?: string;
  value: string | number;
  type?: "text" | "password" | "number" | "email";
  onChange: (changes: string | number) => void;
  endAdornment?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  labelStyle?: object;
  inputStyle?: object;
  isValid?: boolean;
  validationError?: string | "Invalid Input";
  readonly?: boolean;
  noTopMargin?: boolean;
}

export const SimpleInput: FunctionComponent<Props> = ({
  placeholder,
  label,
  value,
  type,
  labelStyle,
  inputStyle,
  onChange,
  endAdornment,
  required = false,
  disabled = false,
  isValid = true,
  validationError,
  noTopMargin,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    onChange(type === "number" ? +event.target.value : event.target.value);
  };

  return (
    <div
      className={"flex flex-col"}
      style={{
        justifyContent: "center",
        margin: noTopMargin ? 0 : "0.4em 0",
      }}
    >
      {label && (
        <label style={{ ...labelStyle, marginBottom: "2vh" }}>
          {label}
          {required && <span className="required"> *</span>}
        </label>
      )}
      {/* <Controller
        name="checkbox"
        control={control}
        rules={{ required: true }}
        render={({ field }) => <Checkbox {...field} />}
      /> */}
      <OutlinedInput
        fullWidth
        value={value || ""}
        type={type || "text"}
        className={styles.input}
        autoComplete="off"
        placeholder={placeholder || ""}
        color="info"
        sx={{ ...inputStyle, border: "none" }}
        onChange={handleChange}
        endAdornment={endAdornment}
        disabled={disabled}
        error={!isValid}
      />
      {!isValid && (
        <FormHelperText error id="accountId-error">
          {validationError}
        </FormHelperText>
      )}
    </div>
  );
};
