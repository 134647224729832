import React from "react";

import { User } from "~/types/data/User.types";

export interface ConnectedUser extends User {
  connected: true;
}

export type UserContextData = ConnectedUser | { connected: false };

export type UserContextContent = {
  userState: UserContextData | undefined;
  setUserState: React.Dispatch<
    React.SetStateAction<UserContextData | undefined>
  >;
};

export const UserContext = React.createContext<UserContextContent>({
  userState: undefined,
  setUserState: () => {},
});

export const useUserContext = () => React.useContext(UserContext);
