import { ApiGetResponse } from "~/types/common/auth.types";
import {
  Project,
  ProjectPostResponse,
  UpdateProjectInput,
} from "~/types/data/Project.types";

import { sendRequest } from "../clients/axios";

export default async function updateProject(
  input: UpdateProjectInput
): Promise<ApiGetResponse<Project>> {
  try {
    const res = await sendRequest<UpdateProjectInput, ProjectPostResponse>(
      "put",
      "/project/update",
      input
    );
    return { success: true, data: res.data.project };
  } catch (error: unknown) {
    return { success: false };
  }
}
