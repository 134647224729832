import { SimpleInput } from "~/components/form/SimpleInput";

import styles from "./index.module.scss";

interface Props {
  label: string;
  value: string;
  onChange: (value: string | number) => void;
}

const SearchBar = ({ label, onChange, value }: Props) => {
  return (
    <div className={`${styles.container}`}>
      <SimpleInput
        label={label}
        onChange={onChange}
        value={value}
        labelStyle={{ textAlign: "center" }}
        inputStyle={{ borderRadius: "20px" }}
        noTopMargin={true}
      />
    </div>
  );
};

export default SearchBar;
