import SVGContainer from "../SVGContainer";
import styles from "./index.module.scss";

interface Props {
  text: string;
  onClick: () => void;
}

const AddButton = ({ onClick, text }: Props) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.textContainer}`}>
        <p>{text}</p>
      </div>
      <div className={`${styles.iconContainer}`}>
        <SVGContainer
          width="100%"
          height="100%"
          imagePath="/assets/addIcon.svg"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default AddButton;
