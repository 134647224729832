import BillabilitySection from "./BillabiltySection";
import ProjectsSection from "./ProjectsSection";
import styles from "./stats.module.scss"

const Stats = () => {
  return (
    <div className={`${styles.stats_container}`}>
      <BillabilitySection />
      <ProjectsSection />
    </div>
  );
};

export default Stats;
