import React from "react";
import ReactDOM from "react-dom";

import Backdrop from "../Backdrop";
import styles from "./index.module.scss";

interface Props {
  show: boolean;
  onCancel: () => void;
}

const ModalOverlay: React.FC = ({ children }) => {
  const content = <div className={`${styles.modal}`}>{children}</div>;

  const element = document.getElementById("modal-hook");
  if (element) {
    return ReactDOM.createPortal(content, element);
  } else {
    return content;
  }
};

const CustomModal: React.FC<Props> = ({ show, onCancel, children }) => {
  return (
    <React.Fragment>
      {show && <Backdrop onClick={onCancel} />}
      {show && <ModalOverlay>{children}</ModalOverlay>}
    </React.Fragment>
  );
};

export default CustomModal;
