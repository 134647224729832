import { useState } from "react";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import FullPageLoader from "~/components/UI/FullPageLoader";
import { useUserContext } from "~/context/userContext";

export const NotAuthenticatedRoute = () => {
  const { userState } = useUserContext();
  const [isNotConnected, setIsNotConnected] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!!userState) {
      setIsNotConnected(!userState.connected);
      setIsLoading(false);
    }
  }, [userState?.connected]);

  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <>{isNotConnected ? <Outlet /> : <Navigate to="/dashboard" />}</>
      )}
    </>
  );
};
