import React, { useEffect, useState } from "react";

import AddOrUpdateProjectForm from "~/components/form/AddProjectForm";
import { CustomizableTable } from "~/components/table/CustomizableTable";
import { HeadCell } from "~/components/type/headCell.type";
import AddButton from "~/components/UI/AddButton";
import CustomModal from "~/components/UI/CustomModal";
import SearchBar from "~/components/UI/SearchBar";
import { useAdminContext } from "~/context/adminContext";
import { Project } from "~/types/data/Project.types";

// interface ProjectTable {
//   id: number;
//   name: string;
//   interlocutorName: string;
//   email: string;
//   phoneNumber: string;
//   startDate: string;
//   endDate: string;
//   value: 30000;
//   type: string;
//   sector: string;
//   developpers: Array<{ firstName: string; id: number }>;
// }

const Projects = () => {
  const { adminDataState, setAdminState } = useAdminContext();

  const [showAddProject, setShowAddProject] = useState(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const showProjectFormHandler = () => {
    setShowAddProject(true);
  };

  const hideProjectFormHandler = () => {
    setSelectedProject(null);
    setShowAddProject(false);
  };

  const updateSelectedProject = (project: Project) => {
    console.log("here", project);
    setAdminState((previousAdminState) => {
      return {
        ...previousAdminState,
        projects: previousAdminState.projects.map((previousProject) => {
          return previousProject.id === project.id ? project : previousProject;
        }),
      };
    });
    hideProjectFormHandler();
  };

  const createProject = (project: Project) => {
    setAdminState((previousAdminState) => {
      return {
        ...previousAdminState,
        projects: [...previousAdminState.projects, project],
      };
    });
    hideProjectFormHandler();
  };

  const searchHandler = (value: string | number) => {
    setSearchValue(value as string);
  };

  // const includesUsers = (users: User[], user: User) => {
  //   let found = false;
  //   users.forEach((userObject) => {
  //     if (userObject.id === user.id) {
  //       found = true;
  //       return;
  //     }
  //   });
  //   return found;
  // };

  // useEffect(() => {
  //   setFilteredProjects(
  //     adminDataState.projects.map((project) => {
  //       return {
  //         ...project,
  //         developpers: adminDataState.users.filter((developper) => {
  //           return includesUsers(project.developpers, developper);
  //         }),
  //       };
  //     })
  //   );
  // }, [adminDataState.users, adminDataState.projects]);

  useEffect(() => {
    const filtered = adminDataState.projects.filter((project) => {
      return project.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    console.log("f", filtered);
    setFilteredProjects(filtered);
  }, [searchValue, adminDataState.projects, adminDataState.users]);

  const projectClickedHandler = (id: number) => {
    const selectedProject = filteredProjects.find((project) => {
      return project.id === id;
    });
    if (selectedProject) {
      setSelectedProject(selectedProject);
      setShowAddProject(true);
    }
  };

  const headCells: HeadCell<Project>[] = [
    { id: "name", type: "string", label: "Name" },
    { id: "email", type: "string", label: "E-mail" },
    { id: "phoneNumber", type: "string", label: "Phone number" },
    { id: "type", type: "string", label: "Type" },
    { id: "sector", type: "string", label: "Sector" },
    { id: "startDate", type: "date", label: "Start date" },
    { id: "endDate", type: "date", label: "End date" },
    { id: "value", type: "number", label: "Value (HT)" },
    { id: "developpers", type: "userArray", label: "Developpers" },
  ];

  return (
    <div className="hp-100">
      <CustomModal onCancel={hideProjectFormHandler} show={showAddProject}>
        <AddOrUpdateProjectForm
          project={selectedProject}
          onUpdate={updateSelectedProject}
          onCreate={createProject}
        />
      </CustomModal>
      <CustomizableTable
        dataRows={filteredProjects}
        headCells={headCells}
        stickyHead={true}
        stickyColumn={true}
        onRowClick={projectClickedHandler}
      >
        <React.Fragment>
          <div style={{ padding: "10px 20px" }}>
            <AddButton text="Add a project" onClick={showProjectFormHandler} />
          </div>
          <div style={{ padding: "10px 20px" }}>
            <SearchBar
              value={searchValue}
              label="Search a project by name"
              onChange={searchHandler}
            />
          </div>
        </React.Fragment>
      </CustomizableTable>
      
    </div>
  );
};

export default Projects;
