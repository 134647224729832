import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { ApiLoginResponse } from "~/types/common/auth.types";
import { AxiosHttpError } from "~/types/common/error.types";

import { setToken, storeAuthResult } from "../auth/utils/tokenManagement.util";

const baseUrl = import.meta.env.VITE_APP_API_BASE_URL as string | undefined;

axios.defaults.baseURL = baseUrl;

setToken();

export default axios;

export async function refreshToken() {
  const refreshToken: string | null =
    typeof window !== "undefined" ? localStorage.getItem("RefreshToken") : null;
  if (!refreshToken) return;
  try {
    const res = await axios.post<
      { refreshToken: string },
      AxiosResponse<ApiLoginResponse>
    >("/auth/refresh-token", { RefreshToken: refreshToken });
    if (res.data.ChallengeName === undefined) storeAuthResult(res.data);
  } catch (e) {
    console.log(e);
  }
}

// request without retry if 401
export async function sendRawRequest<T, R>(
  method: "get" | "post" | "put" | "patch",
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  if (method === "get") return axios.get<T, AxiosResponse<R>>(url, config);
  return axios[method]<T, AxiosResponse<R>>(url, data, config);
}

// request with retry if 401
export async function sendRequest<T, R>(
  method: "get" | "post" | "put" | "patch",
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  try {
    return await sendRawRequest<T, R>(method, url, data, config);
  } catch (error) {
    const axiosError = error as Error | AxiosHttpError;
    if (axios.isAxiosError(axiosError) && axiosError.response?.status === 401) {
      await refreshToken();
      return sendRawRequest<T, R>(method, url, data, config);
    }
    throw error;
  }
}
