import { ApiGetResponse } from "~/types/common/auth.types";
import { CreateProjectInput, Project } from "~/types/data/Project.types";

import { sendRequest } from "../clients/axios";

interface CreateProjectResponse {
  statusCode: number;
  project: Project;
}

export default async function createProject(
  input: CreateProjectInput
): Promise<ApiGetResponse<Project>> {
  try {
    const res = await sendRequest<CreateProjectInput, CreateProjectResponse>(
      "post",
      "/project/create-project",
      input
    );
    return { success: true, data: res.data.project };
  } catch (error: unknown) {
    return { success: false };
  }
}
