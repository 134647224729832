import { InputAdornment } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";

import createUser from "~/api/user/createUser";
import getUploadPath from "~/api/user/getUploadPath";
import updateUser from "~/api/user/updateUser";
import { CustomSelect } from "~/components/form/CustomSelect";
import { SimpleInput } from "~/components/form/SimpleInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { User } from "~/types/data/User.types";
import { teamMemberFormValidationSchema } from "~/utils/validation/user.schema";

import DatePicker from "../DatePicker";
import { FilePicker } from "../FilePicker";
import { FlatButton } from "../FlatButton";
import styles from "./index.module.scss";

interface Props {
  teamMember: User | null;
  onUpdate: (user: User) => void;
  onCreate: (user: User) => void;
}

type OptionType = {
  value: string;
  label: string;
};

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  entryDate: DateTime | null;
  position: string;
  salary: number;
  status: OptionType;
  image: string;
  imageFile?: File;
}

const initFormData: FormState = {
  firstName: "",
  lastName: "",
  email: "",
  entryDate: null,
  position: "",
  salary: 0,
  status: { label: "", value: "" },
  image: "",
};

const AddOrUpdateTeamMemberForm = ({
  teamMember,
  onUpdate,
  onCreate,
}: Props) => {
  const schema = teamMemberFormValidationSchema;

  const [formState, setFormState] = useState<FormState>({
    firstName: teamMember?.firstName || initFormData.firstName,
    lastName: teamMember?.lastName || initFormData.lastName,
    email: teamMember?.email || initFormData.email,
    entryDate: teamMember?.entryDate
      ? DateTime.fromISO(teamMember?.entryDate)
      : initFormData.entryDate,
    position: teamMember?.position || initFormData.position,
    salary: teamMember?.salary || initFormData.salary,
    status: teamMember?.status
      ? { label: teamMember?.status, value: teamMember?.status?.toUpperCase() }
      : initFormData.status,
    image: teamMember?.image || initFormData.image,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    return () => {
      setFormState(initFormData);
    };
  }, []);

  const inputChangeHandler = <T extends keyof FormState>(
    inputName: T,
    changes: FormState[T]
  ) => {
    setFormState((state) => ({ ...state, [inputName]: changes }));
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    const { imageFile, ...input } = formState;
    const result = schema.validate({
      ...input,
      entryDate: formState.entryDate?.toISODate(),
      status: formState.status.value,
    });

    const { error } = result;
    if (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      return;
    }
    let image = formState.image;
    const extension = imageFile?.name.split(".").slice(-1);
    if (extension) {
      const fileName = `${input.firstName}_${input.lastName}`;
      const response = await getUploadPath({
        extension: extension[0],
        fileName,
      });
      if (response.success) {
        const { uploadPath, key } = response.data;
        image = key;
        await fetch(uploadPath, { method: "put", body: imageFile });
      }
    }

    if (teamMember) {
      const { imageFile, ...input } = {
        id: teamMember.id,
        ...formState,
        image,
      };

      const updatedUser = await updateUser({
        ...input,
        id: teamMember.id,
        entryDate: formState.entryDate?.toISO() || new Date().toISOString(),
        status: formState.status.value,
      });
      if (updatedUser.success) {
        console.log("u", updatedUser.data);
        onUpdate(updatedUser.data);
      }
    } else {
      const { imageFile, ...input } = {
        ...formState,
        image,
      };

      const createdUser = await createUser({
        ...input,
        entryDate: formState.entryDate?.toISO() || new Date().toISOString(),
        status: formState.status.value,
      });
      if (createdUser.success) {
        console.log(createdUser.data);
        onCreate(createdUser.data);
      }
    }

    setIsLoading(false);
  };

  return (
    <form
      className={`${styles.form}`}
      onSubmit={(e) => {
        submitHandler(e);
      }}
      id="addTeamMemberForm"
    >
      <div className={`${styles.firstRow}`}>
        <div className={`${styles.firstColumn}`}>
          <SimpleInput
            value={formState.firstName}
            onChange={(value: string | number) =>
              inputChangeHandler("firstName", value.toString())
            }
            type="text"
            label="First Name"
          />
          <SimpleInput
            value={formState.lastName}
            onChange={(value: string | number) =>
              inputChangeHandler("lastName", value.toString())
            }
            type="text"
            label="Last Name"
          />
          <SimpleInput
            value={formState.email}
            onChange={(value: string | number) =>
              inputChangeHandler("email", value.toString())
            }
            type="email"
            label="E-mail"
          />
        </div>
        <div className={`${styles.secondColumn} ${styles.marginLeft}`}>
          <DatePicker
            label="Entry Date"
            value={formState.entryDate}
            onChange={(value: DateTime | null) => {
              inputChangeHandler("entryDate", value);
            }}
          />

          <SimpleInput
            value={formState.position}
            onChange={(value: string | number) =>
              inputChangeHandler("position", value.toString())
            }
            type="text"
            label="Position"
          />
          <SimpleInput
            value={formState.salary}
            onChange={(value: number | string) =>
              inputChangeHandler("salary", value as number)
            }
            type="number"
            label="Salary"
            endAdornment={
              <InputAdornment position="end">
                <p>€</p>
              </InputAdornment>
            }
          />
        </div>
      </div>
      <div className={`${styles.secondRow}`}>
        <div className={`${styles.secondColumn}`}>
          <CustomSelect
            label="Status"
            value={formState.status}
            onChange={(
              value: MultiValue<OptionType> | SingleValue<OptionType>
            ) => inputChangeHandler("status", value as OptionType)}
            options={[
              { label: "Admin", value: "ADMIN" },
              { label: "Viewer", value: "VIEWER" },
            ]}
          />
        </div>
        <div className={`${styles.firstColumn} ${styles.marginLeft}`}>
          <FilePicker
            label="Picture"
            onChange={(value: File | undefined) => {
              inputChangeHandler("image", value?.name || "");
              inputChangeHandler("imageFile", value);
            }}
            value={formState.image}
          />
        </div>
      </div>
      <div className={`${styles.button}`}>
        {!!errorMessage && <p className="text-red">{errorMessage}</p>}
        {isLoading ? (
          <FlatButton backgroundColor="#4361EE" color="white" disabled={true}>
            <SimpleLoader size="size2" />
          </FlatButton>
        ) : (
          <FlatButton
            type="submit"
            form="addTeamMemberForm"
            backgroundColor="#4361EE"
            color="white"
          >
            {!!teamMember ? "Update" : "Add"}
          </FlatButton>
        )}
      </div>
    </form>
  );
};

export default AddOrUpdateTeamMemberForm;
