import React from "react";
import styles from './SimpleDatePicker.module.scss'

interface datePickerProps {
  labelFor:string;
  target:string
}

const DatePicker:React.FC<datePickerProps>=({labelFor,target}) => {
    
    return <React.Fragment>
      <div className={`${styles.container}`}>
      <label htmlFor={labelFor}>{target}</label>
      <input type="date" id={labelFor}></input>
      </div>
    </React.Fragment>
  };


  export default DatePicker