import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";

import createProject from "~/api/project/createProject";
import updateProject from "~/api/project/updateProject";
import { SimpleInput } from "~/components/form/SimpleInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { useAdminContext } from "~/context/adminContext";
import { Project } from "~/types/data/Project.types";
import { projectFormValidationSchema } from "~/utils/validation/project.schema";

import { CustomSelect } from "../CustomSelect";
import DatePicker from "../DatePicker";
import { FlatButton } from "../FlatButton";
import styles from "./index.module.scss";

type OptionType = {
  value: string;
  label: string;
};

interface Props {
  project: Project | null;
  onUpdate: (project: Project) => void;
  onCreate: (user: Project) => void;
}

interface FormState {
  name: string;
  interlocutorName: string;
  email: string;
  phoneNumber: string;
  startDate: DateTime | null;
  endDate: DateTime | null;
  value: number;
  type: string;
  sector: string;
  developpers: OptionType[];
}

const initFormData: FormState = {
  name: "",
  interlocutorName: "",
  email: "",
  phoneNumber: "",
  startDate: null,
  endDate: null,
  value: 0,
  type: "",
  sector: "",
  developpers: [],
};

const AddOrUpdateProjectForm = ({ project, onUpdate, onCreate }: Props) => {
  const { adminDataState } = useAdminContext();
  const schema = projectFormValidationSchema;

  const [formState, setFormState] = useState<FormState>({
    name: project?.name || initFormData.name,
    interlocutorName:
      project?.interlocutorName || initFormData.interlocutorName,
    email: project?.email || initFormData.email,
    phoneNumber: project?.phoneNumber || initFormData.phoneNumber,
    startDate: project?.startDate
      ? DateTime.fromISO(project?.startDate)
      : initFormData.startDate,
    endDate: project?.endDate
      ? DateTime.fromISO(project?.endDate)
      : initFormData.endDate,
    value: project?.value || initFormData.value,
    type: project?.type || initFormData.type,
    sector: project?.sector || initFormData.sector,
    developpers:
      project?.developpers.map((developper) => {
        return {
          label: `${developper.firstName} ${developper.lastName.charAt(0)}.`,
          value: developper.id + "",
        };
      }) || initFormData.developpers,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    return () => {
      setFormState(initFormData);
    };
  }, []);

  const inputChangeHandler = <T extends keyof FormState>(
    inputName: T,
    changes: FormState[T]
  ) => {
    setFormState((state) => ({ ...state, [inputName]: changes }));
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    const result = schema.validate({
      ...formState,
      developpers: formState.developpers
        .map((developper) => {
          return developper.value;
        })
        .join(", "),
      startDate: formState.startDate?.toISODate(),
      endDate: formState.endDate?.toISODate(),
    });

    const { error } = result;
    if (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      return;
    }

    if (project) {
      const input = {
        id: project.id,
        ...formState,
      };

      const updatedProject = await updateProject({
        ...input,
        startDate: formState.startDate?.toISO() || new Date().toISOString(),
        endDate: formState.endDate?.toISO() || new Date().toISOString(),
        developpers: formState.developpers.map((value) => {
          return +value.value;
        }),
      });
      if (updatedProject.success) {
        onUpdate(updatedProject.data);
      }
    } else {
      const createdProject = await createProject({
        ...formState,
        startDate: formState.startDate?.toISO() || new Date().toISOString(),
        endDate: formState.endDate?.toISO() || new Date().toISOString(),
        developpers: formState.developpers.map((id) => {
          return +id;
        }),
      });
      if (createdProject.success) {
        onCreate(createdProject.data);
      }
    }

    setIsLoading(false);
  };

  return (
    <form
      className={`${styles.form}`}
      onSubmit={(e) => {
        submitHandler(e);
      }}
      id="addProjectForm"
    >
      <div className={`${styles.row}`}>
        <div className={`${styles.column}`}>
          <SimpleInput
            value={formState.name}
            onChange={(value: string | number) =>
              inputChangeHandler("name", value.toString())
            }
            type="text"
            label="Name"
          />
          <SimpleInput
            value={formState.interlocutorName}
            onChange={(value: string | number) =>
              inputChangeHandler("interlocutorName", value.toString())
            }
            type="text"
            label="Interlocutor Name"
          />
          <SimpleInput
            value={formState.email}
            onChange={(value: string | number) =>
              inputChangeHandler("email", value.toString())
            }
            type="email"
            label="E-mail"
          />
          <SimpleInput
            value={formState.phoneNumber}
            onChange={(value: string | number) =>
              inputChangeHandler("phoneNumber", value.toString())
            }
            type="text"
            label="Phone Number"
          />
          <CustomSelect
            label="Status"
            multiple={true}
            value={formState.developpers}
            onChange={(
              value: MultiValue<OptionType> | SingleValue<OptionType>
            ) => inputChangeHandler("developpers", value as OptionType[])}
            options={adminDataState.users.map((user) => {
              return {
                label: `${user.firstName} ${user.lastName.charAt(0)}.`,
                value: user.id + "",
              };
            })}
          />
        </div>
        <div className={`${styles.column} ${styles.marginLeft}`}>
          <div className={`${styles.datePickerContainer}`}>
            <DatePicker
              label="Entry Date"
              value={formState.startDate}
              onChange={(value: DateTime | null) => {
                inputChangeHandler("startDate", value);
              }}
            />
          </div>
          <div className={`${styles.datePickerContainer}`}>
            <DatePicker
              label="Entry Date"
              value={formState.endDate}
              onChange={(value: DateTime | null) => {
                inputChangeHandler("endDate", value);
              }}
            />
          </div>
          <SimpleInput
            value={formState.type}
            onChange={(value: string | number) =>
              inputChangeHandler("type", value.toString())
            }
            type="text"
            label="Type"
          />
          <SimpleInput
            value={formState.sector}
            onChange={(value: string | number) =>
              inputChangeHandler("sector", value.toString())
            }
            type="text"
            label="Sector"
          />
          <SimpleInput
            value={formState.value}
            onChange={(value: string | number) =>
              inputChangeHandler("value", value as number)
            }
            type="number"
            label="Value (HT)"
          />
        </div>
      </div>
      <div className={`${styles.button}`}>
        {!!errorMessage && <p className="text-red">{errorMessage}</p>}
        {isLoading ? (
          <FlatButton backgroundColor="#4361EE" color="white" disabled={true}>
            <SimpleLoader size="size2" />
          </FlatButton>
        ) : (
          <FlatButton
            type="submit"
            form="addProjectForm"
            backgroundColor="#4361EE"
            color="white"
          >
            {!!project ? "Update" : "Add"}
          </FlatButton>
        )}
      </div>
    </form>
  );
};

export default AddOrUpdateProjectForm;
