import { ApiGetResponse } from "~/types/common/auth.types";
import { CreateUserInput, User } from "~/types/data/User.types";

import { sendRequest } from "../clients/axios";

interface CreateUserResponse {
  statusCode: number;
  user: User;
}

export default async function createUser(
  input: CreateUserInput
): Promise<ApiGetResponse<User>> {
  try {
    const res = await sendRequest<CreateUserInput, CreateUserResponse>(
      "post",
      "/auth/create-user",
      input
    );
    return { success: true, data: res.data.user };
  } catch (error: unknown) {
    return { success: false };
  }
}
