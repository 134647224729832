import ReactDOM from "react-dom";

import styles from "./index.module.scss";

interface Props {
  onClick: () => void;
}

const Backdrop = ({ onClick }: Props) => {
  const element = document.getElementById("backdrop-hook");
  if (element) {
    return ReactDOM.createPortal(
      <div className={`${styles.backdrop}`} onClick={onClick}></div>,
      element
    );
  } else {
    return <div className={`${styles.backdrop}`} onClick={onClick}></div>;
  }
};

export default Backdrop;
