import { useCallback, useEffect, useState } from "react";

import getProjects from "~/api/project/getProjects";
import getUsers from "~/api/user/getUsers";
import Layout from "~/components/layout/Layout";
import { AdminContext, AdminContextData } from "~/context/adminContext";
import { useUserContext } from "~/context/userContext";
import { User } from "~/types/data/User.types";

import Planning from "./Planning";
import Projects from "./Projects";
import Stats from "./Stats";
import Team from "./Team";

const Dashboard = () => {
  const { userState } = useUserContext();
  const [adminData, setAdminData] = useState<AdminContextData>({
    users: [],
    projects: [],
    mode: "planning",
  });

  const isAuthorized =
    !!userState && userState.connected && userState.status === "ADMIN";

  const initUsers = useCallback(async () => {
    const response = await getUsers();
    if (response.success) {
      setAdminData((previousAdminState) => {
        return { ...previousAdminState, users: [...response.data] };
      });
    } else {
      setAdminData((previousAdminState) => {
        return { ...previousAdminState, users: [] };
      });
    }
  }, []);

  const includesUsers = (users: User[], user: User) => {
    let found = false;
    users.forEach((userObject) => {
      if (userObject.id === user.id) {
        found = true;
        return;
      }
    });
    return found;
  };
  const initProjects = useCallback(async () => {
    const response = await getProjects();
    if (response.success) {
      setAdminData((previousAdminState) => {
        return {
          ...previousAdminState,
          projects: [...response.data],
        };
      });
    } else {
      setAdminData((previousAdminState) => {
        return { ...previousAdminState, projects: [] };
      });
    }
  }, []);
  // const initProjects = useCallback(async () => {
  //   const response = await getProjects();
  //   if (response.success) {
  //     setAdminData((previousAdminState) => {
  //       console.log(previousAdminState.users);
  //       return {
  //         ...previousAdminState,
  //         projects: [...response.data].map((project) => {
  //           return {
  //             ...project,
  //             developpers: previousAdminState.users.filter((developper) => {
  //               return includesUsers(project.developpers, developper);
  //             }),
  //           };
  //         }),
  //       };
  //     });
  //   } else {
  //     setAdminData((previousAdminState) => {
  //       return { ...previousAdminState, projects: [] };
  //     });
  //   }
  // }, []);

  useEffect(() => {
    setAdminData((previousAdminState) => {
      console.log(previousAdminState.users);
      return {
        ...previousAdminState,
        projects: previousAdminState.projects.map((project) => {
          return {
            ...project,
            developpers: previousAdminState.users.filter((developper) => {
              return includesUsers(project.developpers, developper);
            }),
          };
        }),
      };
    });
  }, [adminData.users]);

  useEffect(() => {
    initUsers();
    initProjects();
  }, [initUsers, initProjects]);

  return (
    <AdminContext.Provider
      value={{
        adminDataState: adminData,
        setAdminState: setAdminData,
      }}
    >
      <Layout>
        {adminData.mode === "planning" ? (
          <Planning />
        ) : adminData.mode === "team" && isAuthorized ? (
          <Team />
        ) : adminData.mode === "projects" && isAuthorized ? (
          <Projects />
        ) : adminData.mode === "stats" && isAuthorized ? (
          <Stats />
        ) : (
          ""
        )}
      </Layout>
    </AdminContext.Provider>
  );
};

export default Dashboard;
