import Joi from "joi";

import { CreateProjectInput } from "~/types/data/Project.types";

export const projectFormValidationSchema = Joi.object<CreateProjectInput>({
  name: Joi.string().required().error(new Error("Name can't be empty.")),
  interlocutorName: Joi.string()
    .required()
    .error(new Error("Interlocutor name can't be empty.")),
  email: Joi.string()
    .email({ tlds: false })
    .required()
    .error(new Error("Invalid email.")),
  phoneNumber: Joi.string()
    .required()
    .error(new Error("Phone number can't be empty.")),
  startDate: Joi.string()
    .isoDate()
    .required()
    .error(new Error("Invalid date.")),
  endDate: Joi.string().isoDate().required().error(new Error("Invalid date.")),
  value: Joi.number()
    .required()
    .not(0)
    .error(new Error("Value can't be empty.")),
  type: Joi.string().required().error(new Error("Type can't be empty.")),
  sector: Joi.string().required().error(new Error("Sector can't be empty.")),
  developpers: Joi.string()
    .required()
    .error(new Error("Developpers field can't be empty.")),
});
