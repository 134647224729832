import { ApiGetResponse } from "~/types/common/auth.types";
import { User } from "~/types/data/User.types";

import { sendRequest } from "../clients/axios";

export default async function getUsers(): Promise<ApiGetResponse<User[]>> {
  try {
    const res = await sendRequest<never, User[]>("get", "/users/list");
    return { success: true, data: res.data };
  } catch (error: unknown) {
    return { success: false };
  }
}
