import { ApiGetResponse } from "~/types/common/auth.types";
import { UpdateUserInput, User } from "~/types/data/User.types";

import { sendRequest } from "../clients/axios";

export default async function updateUser(
  input: UpdateUserInput
): Promise<ApiGetResponse<User>> {
  try {
    const res = await sendRequest<UpdateUserInput, User>(
      "put",
      "/users/update",
      input
    );
    return { success: true, data: res.data };
  } catch (error: unknown) {
    return { success: false };
  }
}
