import React, { useEffect, useState } from "react";
import { CustomizableTable } from "~/components/table/CustomizableTable";
import { HeadCell } from "~/components/type/headCell.type";
import SimpleDatePicker from "~/components/simpleDatePicker/SimpleDatePicker";
import styles from "./index.module.scss";
import CustomFooter from "~/components/customFooter/CustomFooter";
import getProjects from "~/api/project/getProjects";
import { Project } from "~/types/data/Project.types";

const ProjectsSection = () => {
  const [projects, setProjects] = useState<Project[]>([]);

  const headCells: HeadCell<Project>[] = [
    {
      id: "name",
      type: "string",
      label: "",
      isEmpty: true,
    },
    {
      id: "id",
      type: "string",
      label: "Number",
      isEmpty: false,
    },
    {
      id: "value",
      type: "string",
      label: "Value (€)",
      isEmpty: false,
    },
    {
      id: "averageValue",
      type: "string",
      label: "Average Value (€)",
      isEmpty: false,
    },
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await getProjects();
      if (res.success && res.data) {
        setProjects(res.data);
      }
    };
    fetchProjects();
  }, []);

  const getTotalValues = (projects: Project[]): number => {
    let total = 0;
    projects.forEach((project) => {
      project.averageValue = project.value;
      total += project.value;
    });
    return total;
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.titleContainer}`}>
        <h2 className={`${styles.title}`}>Projects</h2>
      </div>
      <div className={`${styles.datePickersContainer}`}>
        <SimpleDatePicker labelFor={"startDate"} target={`Start date`} />
        <SimpleDatePicker labelFor={"endDate"} target={`End date`} />
      </div>
      <div className={`${styles.tableContainer}`}>
        <CustomizableTable
          dataRows={projects}
          headCells={headCells}
          stickyHead={true}
          stickyColumn={true}
          hasFoot={true}
        >
          <CustomFooter
            flag="projects"
            name="Overall"
            total={projects.length}
            value={getTotalValues(projects)}
            average={getTotalValues(projects)}
          />
        </CustomizableTable>
      </div>
    </div>
  );
};

export default ProjectsSection;
