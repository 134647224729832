import React from "react";

import NavigationBar from "~/components/navigation/NavigationBar";

import styles from "./index.module.scss";

const Layout: React.FC = ({ children }) => {
  return (
    <div className={`${styles.page}`}>
      <header className={`bgColor-blue flex justify-between ${styles.header}`}>
        <NavigationBar />
      </header>
      <div className={`${styles.content}`}>{children}</div>
    </div>
  );
};

export default Layout;
