import Select, { MultiValue, SingleValue } from "react-select";

type OptionType = {
  value: string;
  label: string;
};
interface Props {
  label: string;
  onChange: (value: MultiValue<OptionType> | SingleValue<OptionType>) => void;
  value: OptionType | OptionType[];
  multiple?: boolean;
  options: OptionType[];
}

export const CustomSelect = ({
  label,
  value,
  onChange,
  multiple,
  options,
}: Props) => {
  return (
    <div
      className={"flex flex-col"}
      style={{ width: "100%", justifyContent: "center", margin: "0.4em 0" }}
    >
      <label style={{ marginBottom: "2vh" }}>{label}</label>
      <Select
        isMulti={multiple}
        value={value}
        options={options}
        onChange={(option) => onChange(option)}
      />
      {/* <Select
          value={value}
          multiple={multiple}
          onChange={(changeEvent) => {
            onChange(changeEvent.target.value);
          }}
          sx={{
            backgroundColor: "#f2f2f2",
            color: "#000",
            height: "40px",
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {items.map((item) => {
            return (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select> */}
    </div>
  );
};
