import styles from "./index.module.scss";

interface Props {
  label: string;
  onChange: (imageFile: File | undefined) => void;
  value: string;
}

export const FilePicker = ({ label, onChange, value }: Props) => {
  const filePickedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files?.length > 0) {
      onChange(files[0]);
    }
  };

  const removeFileHandler = () => {
    onChange(undefined);
  };

  return (
    <div
      className={"flex flex-col"}
      style={{ width: "100%", justifyContent: "center", margin: "0.4em 0" }}
    >
      <label style={{ marginBottom: "2vh" }}>{label}</label>
      <div className={`${styles.container} ${value ? styles.filedPicked : ""}`}>
        <div className={`${styles.fileName}`}>
          <p>{value}</p>
          {!!value && (
            <div
              className={`${styles.deleteButton}`}
              onClick={removeFileHandler}
            >
              <p>x</p>
            </div>
          )}
        </div>
        <div className={`${styles.label}`}>
          <label htmlFor="image">Choose a file</label>
        </div>
        <input
          type="file"
          id="image"
          onChange={filePickedHandler}
          onClick={(e) => {
            e.currentTarget.value = "";
          }}
          hidden
        />
      </div>
    </div>
  );
};
