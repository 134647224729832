import { ApiGetResponse } from "~/types/common/auth.types";

import { sendRequest } from "../clients/axios";

interface UploadPathInput {
  extension: string;
  fileName: string;
}

interface UploadPathResponse {
  key: string;
  uploadPath: string;
}

interface UploadPathResponse {
  status: number;
  data: { uploadPath: string; key: string };
}

export default async function getUploadPath(
  input: UploadPathInput
): Promise<ApiGetResponse<UploadPathResponse>> {
  try {
    const res = await sendRequest<UploadPathInput, UploadPathResponse>(
      "post",
      "/users/get-upload-path",
      input
    );
    return { success: true, data: res.data };
  } catch (error: unknown) {
    return { success: false };
  }
}
