import React from "react";

import { useUserContext } from "~/context/userContext";

import NavItem from "../NavItem";
import styles from "./index.module.scss";

const Navigation = () => {
  const { userState } = useUserContext();

  const protectedNav = (
    <React.Fragment>
      <NavItem title="Team" mode="team" imagePath="/assets/teamIcon.svg" />
      <NavItem
        title="Projects"
        mode="projects"
        imagePath="/assets/projectsIcon.svg"
      />
      <NavItem title="Stats" mode="stats" imagePath="/assets/statsIcon.svg" />
    </React.Fragment>
  );
  return (
    <nav className={`flex ${styles.nav}`}>
      <NavItem
        title="Planning"
        mode="planning"
        imagePath="/assets/planningIcon.svg"
      />
      {userState?.connected && userState.status === "ADMIN" && protectedNav}
    </nav>
  );
};

export default Navigation;
