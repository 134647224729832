import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

import changePassword from "~/api/auth/changePassword";
import { getUserInfo } from "~/api/auth/getUserInfo";
import login from "~/api/auth/login";
import sendForgotPasswordCode from "~/api/auth/sendForgotPasswordCode";
import setNewPassword from "~/api/auth/setNewPassword";
import validateEmail from "~/api/validation/email";
import validateNotEmpty from "~/api/validation/notEmpty";
import validatePassword from "~/api/validation/password";
import { FlatButton } from "~/components/form/FlatButton";
import { SimpleInput } from "~/components/form/SimpleInput";
import { SimpleLoader } from "~/components/UI/SimpleLoader";
import { useUserContext } from "~/context/userContext";

import styles from "./index.module.scss";

interface FormState {
  email: string;
  password: string;
  session: string;
  newPassword: string;
  resetCode: string;
}

interface FormValidityState {
  email: boolean;
  password: boolean;
  newPassword: boolean;
  resetCode: boolean;
}

const initFormData = {
  email: "",
  password: "",
  session: "",
  newPassword: "",
  resetCode: "",
};

const initFormValidityData = {
  email: true,
  password: true,
  newPassword: true,
  resetCode: true,
};

const LoginForm: FunctionComponent = () => {
  const navigate = useNavigate();

  const { setUserState } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<FormState>({ ...initFormData });
  const [formValidityState, setFormValidityState] = useState<FormValidityState>(
    {
      email: true,
      password: true,
      newPassword: true,
      resetCode: true,
    }
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [mode, setMode] = useState("login");

  const inputChangeHandler = <T extends keyof FormState>(
    inputName: T,
    changes: FormState[T]
  ) => {
    setFormState((state) => ({ ...state, [inputName]: changes }));
  };

  const setUserInfo = async () => {
    const response = await getUserInfo();
    if (response.success) setUserState({ ...response.data, connected: true });
  };

  const formSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormValidityState({ ...initFormValidityData });
    setIsLoading(true);
    setErrorMessage("");
    if (mode === "login") {
      const isEmailValid = validateEmail(formState.email);
      const isPasswordValid = validateNotEmpty(formState.password);
      setFormValidityState((prevState) => {
        return { ...prevState, email: isEmailValid, password: isPasswordValid };
      });
      if (!isEmailValid || !isPasswordValid) {
        setIsLoading(false);
        return;
      }

      const input = { email: formState.email, password: formState.password };
      const response = await login(input);
      setIsLoading(false);

      if (response.action === "LOGIN") {
        await setUserInfo();
        setFormState({ ...initFormData });
        navigate("/planning", { replace: true });
      } else if (response.action === "NEW_PASSWORD_REQUIRED") {
        setFormState((prevState) => {
          return {
            ...prevState,
            session: response.session || "",
            password: "",
          };
        });
        setMode("new-password");
      } else {
        if (typeof response.action === "string") {
          setErrorMessage(response.action);
        }
      }
    } else if (mode === "new-password") {
      const isNewPasswordValid = validatePassword(formState.newPassword);
      setFormValidityState((prevState) => {
        return {
          ...prevState,
          newPassword: isNewPasswordValid,
        };
      });
      if (!isNewPasswordValid) {
        setIsLoading(false);
        return;
      }

      const input = {
        email: formState.email,
        session: formState.session || "",
        newPassword: formState.newPassword,
      };
      const response = await setNewPassword(input);
      setIsLoading(false);

      if (response.success) {
        setFormState((prevState) => {
          return {
            ...prevState,
            session: "",
          };
        });
        setMode("login");
      } else {
        if (typeof response.action === "string") {
          setErrorMessage(response.action);
        }
      }
    } else if (mode === "forgot-password") {
      const isEmailValid = validateEmail(formState.email);
      setFormValidityState((prevState) => {
        return { ...prevState, email: isEmailValid };
      });
      if (!isEmailValid) {
        setIsLoading(false);
        return;
      }

      const input = {
        email: formState.email,
      };
      const response = await sendForgotPasswordCode(input);
      setIsLoading(false);

      if (response.success) {
        setMode("confirm-forgot-password");
      } else {
        if (typeof response.action === "string") {
          setErrorMessage(response.action);
        }
      }
    } else if (mode === "confirm-forgot-password") {
      const isResetCodeValid = validateNotEmpty(formState.resetCode);
      const isNewPasswordValid = validatePassword(formState.newPassword);
      setFormValidityState((prevState) => {
        return {
          ...prevState,
          resetCode: isResetCodeValid,
          newPassword: isNewPasswordValid,
        };
      });
      if (!isResetCodeValid || !isNewPasswordValid) {
        setIsLoading(false);
        return;
      }

      const input = {
        email: formState.email,
        code: formState.resetCode,
        password: formState.newPassword,
      };
      const response = await changePassword(input);
      setIsLoading(false);

      if (response.success) {
        setFormState({ ...formState, resetCode: "", newPassword: "" });
        setMode("login");
      } else {
        if (response.action) {
          if (typeof response.action === "string") {
            setErrorMessage(response.action);
          }
        }
      }
    }
  };

  const title =
    mode === "login"
      ? "Login"
      : mode === "new-password"
      ? "New Password"
      : mode === "forgot-password" || mode === "confirm-forgot-password"
      ? "Forgot Password"
      : "Title";

  const buttonLabel =
    mode === "login"
      ? "Login"
      : mode === "new-password"
      ? "Change Password"
      : mode === "forgot-password"
      ? "Send Email"
      : mode === "confirm-forgot-password"
      ? "Reset Password"
      : "Title";

  return (
    <div className="flex flex-col justify-center h-100 p-20">
      <h2 className={`text-center ${styles.title}`}>Welcome Back</h2>
      <h4 className={`main-color text-center ${styles.subtitle}`}>{title}</h4>
      <br />
      <form
        id="loginForm"
        className={`${styles.form}`}
        onSubmit={(e) => {
          formSubmitHandler(e);
        }}
      >
        {(mode === "login" || mode === "forgot-password") && (
          <SimpleInput
            value={formState.email}
            onChange={(value: string | number) =>
              inputChangeHandler("email", value.toString())
            }
            type="email"
            label="E-mail"
            isValid={formValidityState.email}
            validationError="Invalid email."
          />
        )}
        {mode === "login" && (
          <SimpleInput
            value={formState.password}
            onChange={(value: string | number) =>
              inputChangeHandler("password", value.toString())
            }
            type="password"
            label="Password"
            isValid={formValidityState.password}
            validationError="Password cannot be empty."
          />
        )}
        {mode === "login" && (
          <div
            className="flex flex-end text-blue"
            onClick={() => {
              setFormState({
                ...formState,
                password: "",
                resetCode: "",
                newPassword: "",
              });
              setMode("forgot-password");
              setErrorMessage("");
            }}
          >
            <span className="cursor-pointer">Forgot Password</span>
          </div>
        )}
        {mode === "confirm-forgot-password" && (
          <SimpleInput
            value={formState.resetCode}
            onChange={(value: string | number) =>
              inputChangeHandler("resetCode", value.toString())
            }
            type="text"
            label="Code"
            isValid={formValidityState.resetCode}
            validationError="Invalid code."
          />
        )}
        {(mode === "new-password" || mode === "confirm-forgot-password") && (
          <SimpleInput
            value={formState.newPassword}
            onChange={(value: string | number) =>
              inputChangeHandler("newPassword", value.toString())
            }
            type="password"
            label="New Password"
            isValid={formValidityState.newPassword}
            validationError="Your password must be at least 8 characters long, contain at least one number, one special character, and have a mixture of uppercase and lowercase letters."
          />
        )}

        <div className={`${styles.button}`}>
          {!!errorMessage && <p className="text-red">{errorMessage}</p>}
          {isLoading ? (
            <FlatButton backgroundColor="#4361EE" color="white" disabled={true}>
              <SimpleLoader size="size2" />
            </FlatButton>
          ) : (
            <FlatButton
              type="submit"
              form="loginForm"
              backgroundColor="#4361EE"
              color="white"
            >
              {buttonLabel}
            </FlatButton>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
